export const enterFullScreen = (element: HTMLElement | null) => {
  if (!element) return;
  const requestFullscreen =
    element.requestFullscreen ||
    (element as any).webkitRequestFullscreen || 
    (element as any).mozRequestFullScreen ||
    (element as any).msRequestFullscreen; 
  if (requestFullscreen) {
    requestFullscreen.call(element).catch((err: any) =>
      console.error("Error attempting to enable fullscreen mode:", err)
    );
  } else {
    const gameContainer = document.getElementById('unity-container');
    if (gameContainer) {
      gameContainer.style.height = `${window.innerHeight}px`;
    }
    console.warn("Fullscreen API is not supported in this browser.");
  }
};

export const lockOrientation = (
  orientation: "landscape-primary" | "portrait-primary"
) => {
  const screenOrientation =
    window.screen.orientation ||
    (window.screen as any).mozOrientation ||
    (window.screen as any).msOrientation;

  if (screenOrientation && 'lock' in screenOrientation) {
    (screenOrientation as any)
      .lock(orientation)
      .catch((err: any) =>
        console.error("Error locking orientation:", err.message)
      );
  } else {
    console.warn("Screen orientation lock is not supported.");
  }
};
export const isMobileDevice = (): boolean => {
  const userAgent =
    typeof window.navigator === "undefined" ? "" : window.navigator.userAgent;
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(userAgent);
};

export const isIOS=():boolean=> {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}
export const isFullScreen = () => {
  return !!document.fullscreenElement || 
         !!(document as any).webkitFullscreenElement ||
         !!(document as any).msFullscreenElement;
};