import Cookies from "js-cookie";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  enterFullScreen,
  isFullScreen,
  isMobileDevice,
  lockOrientation,
} from "../utils/fullscreenUtils";
import { SpriteAnimation } from "../utils/SpriteAnimation";
import { useWebSocket } from "../utils/WebSocket";
import GamePopups from "../utils/GamePopups";

declare global {
  interface Window {
    redirectToHome: () => void;
    setMusicVolume: (val: number) => void;
    setSoundVolume: (val: number) => void;
    SendMessageToJS: (message: string) => void;
    openLowBalancePopup: () => void;
    openInActivePopup: () => void;
    ReactNativeWebView?: any;
  }
}
interface UnityWebGLPlayerProps {
  table: {
    slug: string;
    tableName: string;
    orientation: "landscape-primary" | "portrait-primary";
  };
  key?: number;
}

const UnityWebGLPlayer: React.FC<UnityWebGLPlayerProps> = ({ table }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const unityInstanceRef = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [popupName, setPopupName] = useState<string>(""); // Store game status
  const deviceType = localStorage.getItem("deviceType");
  const username = sessionStorage.getItem("username");
  const [showOverlay, setShowOverlay] = useState(false);
  const lastTapRef = useRef(0);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isWebView, setIsWebView] = useState(false);
  // const [showVideo, setShowVideo] = useState(false);

  const navigate = useNavigate();
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };
  // useEffect(() => {
  //   return () => {
  //     // Cleanup Unity instance
  //     quitUnityInstance();
  //   };
  // }, []);
  const handleVideoError = (error: any) => {
    console.error("Video loading error:", error);
    setIsVideoLoading(true);
  };
  useEffect(() => {
    const detectWebView = () => {
      // Check for React Native WebView
      const isReactNativeWebView = window.ReactNativeWebView !== undefined;
      // Check for common WebView properties
      const isWebView =
        isReactNativeWebView ||
        navigator.userAgent.toLowerCase().includes("wv") ||
        /Android.*Version\/[0-9].[0-9]/.test(navigator.userAgent);

      setIsWebView(isWebView);
    };

    detectWebView();
  }, []);
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Force video to play without controls
      video.setAttribute("playsinline", "true");
      video.setAttribute("webkit-playsinline", "true");
      video.setAttribute("muted", "true");
      video.muted = true;

      // Attempt to start playing as soon as possible
      const playVideo = async () => {
        try {
          await video.play();
        } catch (err) {
          console.error("Video autoplay failed:", err);
        }
      };

      playVideo();

      // Handle video events
      const handleCanPlay = () => {
        setIsVideoLoading(false);
        playVideo();
      };

      video.addEventListener("canplay", handleCanPlay);

      return () => {
        video.removeEventListener("canplay", handleCanPlay);
        video.pause();
        video.src = "";
      };
    }
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      width: table.orientation === "portrait-primary" ? "420px" : "100%",
      height:
        table.orientation === "portrait-primary"
          ? "100%"
          : ` min(640px, ${deviceType === "desktop" ? "80%" : "100%"})`,
      minHeight: deviceType === "desktop" ? "370px" : 0,
      position: "relative" as const,
      backgroundColor: "#212123",
      margin: "auto",
    },
    canvas: {
      flexGrow: 1,
      display: "block",
      width: "100%",
      height: "100%",
      //     minHeight: "500px", // Prevents shrinkage
      // aspectRatio: "16/9",
    },
    loader: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
      fontSize: "1.5em",
      fontFamily: "Arial, sans-serif",
    },
    video: {
      width: "100%",
      height: "100%",
      position: "absolute" as const,
      top: 0,
      left: 0,
      objectFit: "cover" as const,
      zIndex: 10,
      opacity: isVideoLoading ? 0 : 1,
      transition: "opacity 0.5s ease-in-out",
      pointerEvents: "none" as const,
      touchAction: "none" as const,
      playsInline: true,
      WebkitPlaysinline: true,
    },
    overlayStyles: {
      position: "fixed" as const,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.3)",
      zIndex: 9999,
      display: showOverlay ? ("flex" as const) : ("none" as const),
      touchAction: "manipulation" as const, // Changed from 'none'
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "auto" as const, // Crucial for touch detection
    },
    instructionText: {
      color: "#fff",
      fontSize: "24px",
      textAlign: "center" as const,
      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
      padding: "20px",
      backgroundColor: "rgba(0,0,0,0.6)",
      borderRadius: "10px",
    },
  };
  useEffect(() => {
    const redirectToHome = async () => {
      if (
        window.screen.orientation &&
        (window.screen.orientation as any).unlock
      ) {
        try {
          await (window.screen.orientation as any).unlock();
        } catch (error) {
          console.error("Failed to unlock orientation:", error);
        }
      }
      await quitUnityInstance();
      navigate("/lobby");
    };

    const openInActivePopup = async () => {
      setPopupName("Inactive");
      setIsPopupOpen(true);
    };

    const openLowBalancePopup = async () => {
      setPopupName("LowBalance");
      setIsPopupOpen(true);
    };

    const setMusicVolume = (val: number) => {
      Cookies.set(`${username}_music`, val.toFixed(2), { expires: 365 });
    };

    const setSoundVolume = (val: number) => {
      Cookies.set(`${username}_sound`, val.toFixed(2), { expires: 365 });
    };

    const SendMessageToJS = (message: string) => {
      if (message === "HelloFromUnity") {
        return;
      }
      console.log("__check Sending message to server in player: ", message);
      sendMessage(message);
    };

    window.redirectToHome = redirectToHome;
    window.setMusicVolume = setMusicVolume;
    window.setSoundVolume = setSoundVolume;
    window.SendMessageToJS = SendMessageToJS;
    window.openInActivePopup = openInActivePopup;
    window.openLowBalancePopup = openLowBalancePopup;
  }, [navigate, username]);

  const initializeUnityInstance = useCallback(async () => {
    await quitUnityInstance();
    await new Promise((resolve) => setTimeout(resolve, 500));
    unityInstanceRef.current = null;
    const buildUrl = `/UnityBuilds/${table.slug}`;
    // const buildUrl =
    //   deviceType === "mobile" && isWebView
    //     ? `/UnityRotateBuilds/${table.slug}`
    //     : `/UnityBuilds/${table.slug}`;
    const loaderUrl = `${buildUrl}/Build.loader.js`;
    const config = {
      dataUrl: `${buildUrl}/Build.data.unityweb`,
      frameworkUrl: `${buildUrl}/Build.framework.js.unityweb`,
      codeUrl: `${buildUrl}/Build.wasm.unityweb`,
      companyName: "slot-games",
      productName: table.tableName,
      productVersion: "1.0",
    };

    const script = document.createElement("script");
    script.src = loaderUrl;

    script.onload = () => {
      if (typeof createUnityInstance !== "undefined" && canvasRef.current) {
        createUnityInstance(canvasRef.current, config, (progress) => {
          setLoading(progress !== 1);
        })
          .then((unityInstance) => {
            unityInstanceRef.current = unityInstance;
            if (sessionStorage.getItem("token")) {
              unityInstance.SendMessage(
                "WebSocket",
                "OnServerUrlReceived",
                "https://backend.inferixai.link"
              );
              unityInstance.SendMessage(
                "WebSocket",
                "OnJSSessionVarReceived",
                sessionStorage.getItem("token")
              );
              unityInstance.SendMessage(
                "WebSocket",
                "getBetInfo",
                sessionStorage.getItem("ws_info")
              );
              console.log("ws_info", sessionStorage.getItem("ws_info"));
              setTimeout(() => {
                unityInstance.SendMessage(
                  "SoundManager",
                  "MusicSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_music`) || "0.50")
                );
                unityInstance.SendMessage(
                  "SoundManager",
                  "SoundSettingsFromWeb",
                  parseFloat(Cookies.get(`${username}_sound`) || "0.50")
                );
              }, 5000);
            }
            console.log("Unity instance initialized successfully.");
          })
          .catch((err) =>
            console.error("Unity instance initialization error:", err)
          );
      } else {
        console.error("createUnityInstance is not defined.");
      }
    };
    document.body.appendChild(script);
  }, [table, username]);

  const adjustForDevice = useCallback(() => {
    const container = containerRef.current;
    const isMobile = isMobileDevice();

    if (isMobile && container) {
      console.log("Enabling fullscreen for mobile.");
      enterFullScreen(container);
      lockOrientation(table.orientation);
    } else {
      console.log("Desktop detected. No fullscreen triggered.");
    }
  }, []);

  const quitUnityInstance = async () => {
    if (unityInstanceRef.current) {
      try {
        await unityInstanceRef.current.Quit();
        unityInstanceRef.current = null;
        const unityFiles = [
          "Build.data.unityweb",
          "Build.framework.js.unityweb",
          "Build.wasm.unityweb",
        ];
        unityFiles.forEach((file) => {
          const elements = document.querySelectorAll(
            `script[src*='${file}'], link[href*='${file}'], script[src*='Build.loader.js']`
          );
          elements.forEach((el) => el.remove());
        });
        if ("caches" in window) {
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
            console.log("🗑️ Cleared Unity WebGL cache storage.");
          });
        }
        await new Promise((resolve) => setTimeout(resolve, 500)); // Small delay for proper cleanup
        console.log("✅ Unity instance fully disposed. check");
      } catch (error) {
        console.error("❌ Error during Unity disposal: check", error);
      }
    }
  };

  useEffect(() => {
    initializeUnityInstance();
    adjustForDevice();
    return () => {
      quitUnityInstance();
    };
  }, [initializeUnityInstance, adjustForDevice]);

  const { sendMessage, addMessageListener, removeMessageListener } =
    useWebSocket({
      onOpen: () => console.log("UnityPlayer WebSocket connection opened"),
      onClose: () => console.log("UnityPlayer WebSocket connection closed"),
      onError: (error) => console.error("UnityPlayer WebSocket error:", error),
    });

  const handleMessage = (data: any) => {
    if (unityInstanceRef.current) {
      unityInstanceRef.current.SendMessage(
        "WebSocketBridge",
        "ReceiveMessageFromJS",
        JSON.stringify(data)
      );
    }
  };

  useEffect(() => {
    addMessageListener(handleMessage);
    return () => removeMessageListener(handleMessage);
  }, [addMessageListener, removeMessageListener]);

  useEffect(() => {
    const checkConnectivity = async () => {
      let stat = "No Internet";
      if (!navigator.onLine) {
        setStatus("No Internet");
        return;
      }
      const authToken = sessionStorage.getItem("token");
      try {
        // Make a test API call with a timeout of 5 seconds
        const tablesResponse = await axios.get(
          "https://backend.inferixai.link/api/",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            timeout: 3000,
          }
        );

        if (tablesResponse.status === 200) {
          stat = "Wi-Fi";
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Axios-specific error handling
          if (error.code === "ECONNABORTED") {
            console.error("API call timed out:", error.message);
            stat = "No Internet"; // Set to "No Internet" on timeout
          } else if (error.response) {
            console.error(
              "API call failed with response:",
              error.response.status,
              error.response.data
            );
            stat = "No Internet";
          } else {
            console.error("API call failed with no response:", error.message);
            stat = "No Internet";
          }
        } else {
          // Non-Axios errors
          console.error("Unexpected error:", error);
          stat = "No Internet";
        }
      }

      // Update status state
      setStatus(stat);

      // Send status to Unity
      if (unityInstanceRef.current) {
        unityInstanceRef.current.SendMessage(
          "NetWorkManager",
          "ReceiveNetworkStatusFromJS",
          stat
        );
      }
    };

    checkConnectivity();

    // Periodically check connectivity
    const intervalId = setInterval(checkConnectivity, 10000);

    // Add event listeners for online/offline
    const handleOnline = () => checkConnectivity();
    const handleOffline = () => checkConnectivity();
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (status === "No Internet") {
      setPopupName("NoInternet");
      setIsPopupOpen(true);
    } else {
      setPopupName("");
      setIsPopupOpen(false);
    }
  }, [status]);
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  const checkFullscreenStatus = () => {
    if (isMobileDevice() && !isIOS) {
      setShowOverlay(!isFullScreen());
    }
  };

  useEffect(() => {
    setIsStandalone(
      window.matchMedia("(display-mode: standalone)").matches ||
        (navigator as any).standalone
    );
  }, []);
  useEffect(() => {
    if (isMobileDevice()) {
      checkFullscreenStatus();
    }
  }, []);

  useEffect(() => {
    const handler = () => checkFullscreenStatus();
    document.addEventListener("fullscreenchange", handler);
    document.addEventListener("webkitfullscreenchange", handler);
    return () => {
      document.removeEventListener("fullscreenchange", handler);
      document.removeEventListener("webkitfullscreenchange", handler);
    };
  }, []);

  const handleDoubleTap = (e: React.TouchEvent) => {
    e.preventDefault();
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapRef.current;

    if (tapLength < 300 && tapLength > 0) {
      requestAnimationFrame(async () => {
        try {
          // Enter full-screen first
          await enterFullScreen(containerRef.current);

          // Delay locking orientation slightly to ensure full-screen is active
          setTimeout(() => {
            lockOrientation(table.orientation);
          }, 200);
        } catch (err) {
          console.error("Error entering full-screen:", err);
        }
      });
    }
    lastTapRef.current = currentTime;
  };

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !("MSStream" in window);

  return (
    <div id="unity-container" style={styles.container} ref={containerRef}>
      <canvas
        id="unity-canvas"
        style={styles.canvas}
        ref={canvasRef}
        key={`canvas-${table.slug}`}
      ></canvas>
      {isVideoLoading && (
        <div style={styles.loader}>
          <SpriteAnimation
            spriteSheetImage={"sprites/fiesta_loader.png"}
            frameWidth={150}
            frameHeight={150}
            totalFrames={49}
            rows={7}
            cols={7}
            fps={21}
          />
        </div>
      )}
      {loading && (
        <video
          ref={videoRef}
          src={`/LoadingScreens/${table.slug}.mp4`}
          autoPlay
          loop
          muted
          webkit-playsinline="true"
          controls={false}
          style={styles.video}
          onLoadedData={handleVideoLoad}
          onError={handleVideoError}
        />
      )}
      {isMobileDevice() && !isWebView && (
        <div
          style={styles.overlayStyles}
          onTouchEnd={!isIOS ? handleDoubleTap : () => setShowOverlay(false)}
          onClick={
            !isIOS
              ? () => handleDoubleTap({} as React.TouchEvent)
              : () => setShowOverlay(false)
          }
        >
          {!isIOS && !isStandalone && (
            <div style={styles.instructionText}>
              Double tap anywhere to enter fullscreen
            </div>
          )}
        </div>
      )}

      {isPopupOpen && (
        <GamePopups
          isOpen={isPopupOpen}
          onClose={handlePopupClose}
          popupName={popupName}
        />
      )}
    </div>
  );
};
export default UnityWebGLPlayer;
