import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import UnityWebGLPlayer from "../../components/gamePlayer/UnityWebGLPlayer";
import RotatePhone from "../../components/utils/RotatePhone";
import SideNav from "../../components/utils/Sidenav";
import { RootState } from "../../store/store";
import { Table } from "../../store/tablesModel";
import { TableDataMap } from "../../components/utils/constants/tablesData";
import { tablesLoaded } from "../../store/tablesSlice";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { isIOS } from "../../components/utils/fullscreenUtils";
const SlotGame: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const tables = useSelector((state: RootState) => state.tables);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const deviceType = localStorage.getItem("deviceType");
  const [isPortrait, setIsPortrait] = useState(false);
  const [selectedTable, setSelectedTable] = useState<Table | undefined>(undefined);
  const [preferredOrientation, setPreferredOrientation] = useState<
    "landscape-primary" | "portrait-primary" | undefined
  >(undefined);
  const [isValidating, setIsValidating] = useState(true);
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [gameKey, setGameKey] = useState(0);
  const dispatch = useDispatch();


  useEffect(() => {
    const validateTokenAndInitialize = async () => {
      const tokenParam = searchParams.get("token");
      const userName = searchParams.get("userName");
      const sessionToken = sessionStorage.getItem("token");
      const userType = searchParams.get("userType");
      if (sessionToken && !tokenParam) {
        setIsValidating(false);
        return;
      }
      if (!tokenParam || !userName) {
        setIsValidating(false);
        setError("Token not Found")
        return;
      }
      if (tokenParam && userName) {
        setIsValidating(true);
        try {
          const response = await axios.get(
            "https://backend.inferixai.link/api/v1/validate-token",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenParam}`,
              },
            }
          );

          if (response && response.data.description === "Token valid") {
            setToken(tokenParam);
            sessionStorage.setItem("token", tokenParam);
            sessionStorage.setItem("username", userName);
            if (userType) {
              sessionStorage.setItem("userType", userType);
            }
            getTables();
            const currentPath = window.location.pathname;
            navigate(currentPath, { replace: true });
          } else {
            throw new Error("Invalid token");
          }
        } catch (err) {
          console.error("Token validation failed", err);
          setError("Invalid token");
        } finally {
          setIsValidating(false);
        }
      } else {
        setIsValidating(false);
      }
    };

    validateTokenAndInitialize();
  }, [searchParams, navigate]);

    

    const getTables = async () => {
      if (!token && !sessionStorage.getItem("token")){
        // setError("Token not found");
         return;
      }
      setError("");

      try {
        setIsValidating(true);
        const authToken = sessionStorage.getItem("token") || token;
        const response = await axios.get(
          "https://backend.inferixai.link/api/gettables",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data && response.data.status === "RS_OK") {
          const fetchedTables: Table[] = response?.data.tables.map(
            (table: any) => ({
              tableId: table.tableId,
              tableName: table.tableName,
              category: table.category,
              slug: table.slug,
              orientation: table.orientation as
                | "landscape-primary"
                | "portrait-primary",
            })
          );

          const filteredTables = fetchedTables?.filter(
            (table) => table.slug in TableDataMap
          );

          dispatch(tablesLoaded(filteredTables));
          dispatch(favTablesLoaded(response.data.favTables));
        } else {
          dispatch(tablesLoaded([])); // Clear tables
          dispatch(favTablesLoaded([])); // Clear favorite tables
          throw new Error(response.data.message);
        }
      } catch (err: any) {
        setIsValidating(false);
        dispatch(tablesLoaded([])); // Clear tables
        dispatch(favTablesLoaded([])); // Clear favorite tables
        console.error(err);
        setError(err.response?.data?.error || "Failed to load tables");
      }
      finally{
        setIsValidating(false);
      }
    };

    useEffect(() => {
      const lockOrientation = async () => {
        if (window.screen.orientation && (window.screen.orientation as any).lock) {
          try {
            await (window.screen.orientation as any).lock("landscape");
            console.log("Screen orientation locked to landscape.");
          } catch (error) {
            console.error("Failed to lock screen orientation:", error);
          }
        } else {
          console.warn("Screen Orientation API not supported in this browser.");
        }
      };
  
      lockOrientation();
    }, []);

    const checkOrientation = useCallback(() => {
      const newIsPortrait = window.matchMedia("(orientation: portrait)").matches;
      const hasOrientationChanged = isPortrait !== newIsPortrait;
      
      setIsPortrait(newIsPortrait);
      
      if (hasOrientationChanged && preferredOrientation) {
        const shouldBePortrait = preferredOrientation === "portrait-primary";
        if (newIsPortrait !== shouldBePortrait) {
          setGameKey(prev => prev + 1);
        }
      }
    }, [isPortrait, preferredOrientation]);

 useEffect(() => {
    checkOrientation();
    
    const orientationHandler = () => {
      // Small delay to ensure orientation has fully changed
      setTimeout(checkOrientation, 100);
    };

    window.addEventListener("resize", orientationHandler);
    window.addEventListener("orientationchange", orientationHandler);

    return () => {
      window.removeEventListener("resize", orientationHandler);
      window.removeEventListener("orientationchange", orientationHandler);
    };
  }, [checkOrientation]);
 
  const isOrientationMismatch = useMemo(() => {
    return (
      deviceType !== "desktop" &&
      ((preferredOrientation === "landscape-primary" && isPortrait) ||
        (preferredOrientation === "portrait-primary" && !isPortrait))
    );
  }, [deviceType, preferredOrientation, isPortrait]);

  const updateSelectedTable = useCallback(() => {
    if (gameId && tables.length > 0) {
      const selectedGame = tables.find((table) => table.slug === gameId);
      if (selectedGame) {
        setSelectedTable(selectedGame);
        setPreferredOrientation(selectedGame.orientation);
        console.log("Selected table updated:", selectedGame);
      } else {
        console.log("No matching table found for gameId:", gameId);
      }
    } else {
      console.log("Cannot update selected table. GameId:", gameId, "Tables:", tables);
    }
  }, [gameId, tables]);

  useEffect(() => {
    updateSelectedTable();
  }, [updateSelectedTable]);

  if (isValidating) {
    <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
      Validating token...
    </div>;
  }

  if (error) {
    return (
      <div className="w-full p-2  text-red-600 text-3xl font-medium text-center">
        {error}
      </div>
    );
  }

  return (
      <div className="flex h-full w-full">
        {!isValidating&&(
          <>
       {(isOrientationMismatch && isIOS()) && (
        
        <RotatePhone orientation={preferredOrientation} />
     
      )} 
      {!(isOrientationMismatch && isIOS() )&& (
        <>
          {deviceType === "desktop" && (
            <div
              className="hidden sm:flex w-[18%] min-w-[240px] max-w-[340px] bg-black/[0.75]"
              style={{ boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)" }}
            >
              <SideNav excludeTableId={selectedTable?.tableId} />
            </div>
          )}
          <div
            className={`flex w-full h-full p-0 ${
              deviceType === "desktop" && "sm:p-12"
            }`}
          >
            <div
              className={`flex h-full w-full ${
                deviceType === "desktop" &&
                "sm:w-10/12 sm:justify-between sm:mx-auto"
              }`}
            >
              {(!isValidating)&&gameId && selectedTable ? (
                <UnityWebGLPlayer table={selectedTable}  key={gameKey}  />
              ) : (
                <>Please Select a game to play</>
              )}
            </div>
          </div>
        </>
   )} 
      </>
      )}

    </div>
  );
};

export default SlotGame;

